<template>
  <div class="refund-detail scroll-container">
    <CategoryTitle :category="category" />
    <div class="grey lighten-1 rounded-md px-6 py-3 px-sm-3">
      <v-row
        no-gutters
        class="text-body-2 my-3 refund-table-details"
        v-if="refund"
      >
        <v-col cols="12" sm="6" class="first-column">
          <div class="refund-table-cell refund-number">
            <strong class="cell-title"
              >{{ $t("refund.refundNumber") }}:
            </strong>
            <strong>#{{ refund.listId }}</strong>
          </div>
          <div class="refund-table-cell refund-date">
            <strong class="cell-title">{{ $t("refund.date") }}: </strong>
            {{ $dayjs(refund.timeslotDate).format("DD MMMM YYYY") }}
          </div>
          <div class="refund-table-cell refund-order-number">
            <strong class="cell-title">{{ $t("refund.orderNumber") }}: </strong>
            <span
              @click="$router.push(`/profile/orders/${refund.orderId}`)"
              class="primary--text font-weight-600 route-link"
              >#{{ refund.orderId }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="refund-table-cell refund-products">
            <strong class="cell-title">{{ $t("refund.products") }}: </strong>
            <span v-if="refund.wishlistItems">{{
              refund.wishlistItems.length
            }}</span>
          </div>
          <div class="refund-table-cell refund-status">
            <strong class="cell-title">{{ $t("refund.status") }}:</strong>
            <span v-if="refund.wishlistStatusId">{{
              $t(`refund.refundStatus.${refund.wishlistStatusId}`)
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row
      no-gutters
      class="text-body-2 mt-5 mb-2 headers grey lighten-1 rounded-md py-1"
      align="center"
      justify="space-between"
    >
      <v-col cols="8" sm="10" md="8">
        {{ $t("lists.header.product") }}
      </v-col>
      <v-col cols="4" sm="2" md="2">
        {{ $t("lists.header.qty") }}
      </v-col>
    </v-row>
    <div v-for="item in refund.wishlistItems" :key="item.itemId" class="mt-3">
      <RefundItem :item="item" />
    </div>
    <div class="mt-6">
      <h5 class="font-weight-600">
        {{ $t(`refund.methodOfReturn`) }}:
        {{ $t(`refund.refundOptions.${refund.wishlistTypeId}`) }}
      </h5>
    </div>

    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>

<style scoped lang="scss">
.refund-detail {
  .refund-table-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: flex-start;
      gap: 8px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .first-column {
      border-right: 1px solid var(--v-grey-base);
    }
  }
  .headers {
    font-weight: 600;
    padding: 0 16px;
  }
  .v-list {
    background-color: transparent;
  }
}
</style>

<script>
import RefundService from "@/service/refundService";
import CategoryTitle from "@/components/category/CategoryTitle";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import RefundItem from "@/components/orders/RefundItem.vue";

export default {
  name: "RefundDetail",
  data() {
    return { refund: {} };
  },
  mixins: [reload, categoryMixin],
  components: {
    CategoryTitle,
    RefundItem
  },

  computed: {},
  methods: {
    async reload(refundId) {
      this.refund = await RefundService.viewRefund(refundId);
    }
  },
  created() {
    this.reload(this.$route.params.refundId);
  }
};
</script>
